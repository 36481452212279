// Generated by Framer (de6f63d)

import { addFonts, cx, CycleVariantState, RichText, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["vewiJjfOc"];

const serializationHash = "framer-MvhOy"

const variantClassNames = {vewiJjfOc: "framer-v-rs0t89"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "vewiJjfOc", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-MvhOy", ...sharedStyleClassNames, classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-rs0t89", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"vewiJjfOc"} ref={ref ?? ref1} style={{...style}}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "Q1VTVE9NO1RBU0EgT3JiaXRlciBEZWNrIFNlbWlCb2xk", "--framer-font-family": "\"TASA Orbiter Deck SemiBold\", \"TASA Orbiter Deck SemiBold Placeholder\", sans-serif", "--framer-font-size": "12px", "--framer-line-height": "1.3em", "--framer-text-alignment": "left", "--framer-text-transform": "uppercase"}}> • GLOBAL NETWORK • </motion.p></React.Fragment>} className={"framer-z6ts6h"} fonts={["CUSTOM;TASA Orbiter Deck SemiBold"]} layoutDependency={layoutDependency} layoutId={"cw6c7B4v6"} style={{"--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline", "--framer-paragraph-spacing": "0px"}} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-MvhOy [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-MvhOy .framer-1klsnke { display: block; }", ".framer-MvhOy .framer-rs0t89 { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 16px; justify-content: center; overflow: hidden; padding: 0px 0px 0px 0px; position: relative; width: min-content; }", ".framer-MvhOy .framer-z6ts6h { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-MvhOy .framer-rs0t89 { gap: 0px; } .framer-MvhOy .framer-rs0t89 > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-MvhOy .framer-rs0t89 > :first-child { margin-left: 0px; } .framer-MvhOy .framer-rs0t89 > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 16
 * @framerIntrinsicWidth 121
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","fixed"]}}}
 * @framerImmutableVariables true
 */
const FramerSNke0woIx: React.ComponentType<Props> = withCSS(Component, css, "framer-MvhOy") as typeof Component;
export default FramerSNke0woIx;

FramerSNke0woIx.displayName = "Ticker - Small";

FramerSNke0woIx.defaultProps = {height: 16, width: 121};

addFonts(FramerSNke0woIx, [{family: "TASA Orbiter Deck SemiBold", moduleAsset: {localModuleIdentifier: "local-module:canvasComponent/SNke0woIx:default", url: "https://framerusercontent.com/assets/TwmQvYOoOpQ8HnPeFBoRgoo91rU.otf"}, url: "https://framerusercontent.com/assets/TwmQvYOoOpQ8HnPeFBoRgoo91rU.otf"}])